*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.hero {
    width:85vw;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    /* margin-bottom:6rem;
    margin-top:6rem; */
}
.hero .left{
    width:100%
}
.hero .right {
    width:100%;
    
    color:#ffffff;
}

.hero img{
 width:100%;
}

.voucher-container{
    width:90vw;
    text-align: left;
    background-color:#D4D4D8;
    border-radius: 1vmax;
    margin:2rem;
    padding-top:2rem;
    padding-bottom:3rem;
        display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
.voucher-progress-bar{
    /* width:80%; */
    text-align: center;
    margin:1.5rem 0;
    display: flex
;
    flex-direction: column;
    align-items: center;
}

.voucher-container *{
    width:90%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
/* .voucher-container *{
    width:80%;
} */
/* .voucher-highlight{
    max-width:70vw;
    margin:1rem;
    padding:1rem;
    border-radius:2vmax;
    display: flex
;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

/* .voucher-highlight *{
    line-height:1.3rem;

} */
.voucher-progress-bar-outer{
    width:80%;
    margin:1rem 0;
    background-color: #e0e0e0;
    border-radius:50vmax;
    display:block;
}
.voucher-progress-bar-inner{
    width:5%;
    height:20px;
    background-color:#76c7c0;
    border-radius:50vmax;
   
}
.voucher-progress-bar img{

}
.service-list{
      width:90vw;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    /* transform:scale(.8) */
}



.service-list-item{
    /* min-width:150px;
    max-width:150px; */
    /* height:185px; */
    max-width:310px;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    padding:.5rem 1rem;
    margin:1rem;
    border-radius: 1vmax;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    
}

.service-list-item img{
    width:50px;
    height:50px;
    margin:1rem;
}
.secondary-btn{
    width:150px;
    background-color: black;
    color:white;
    padding:1rem;
    border-radius:.5vmax;
    border:none;
    margin:1rem;
}

.tertiary-btn{
    width:150px;
    background-color: transparent;
    color:black;
    padding:1rem;
    border-radius:.5vmax;
    border:1px solid black;
    margin:1rem;

}

.primary-btn{
    width:150px;
    padding:1rem 2.5rem;
    margin:1rem;
    background-color: #022350;
    color:white;
    border-radius: .5vmax;
    border:none;
}

.primary-btn:hover, .secondary-btn:hover, .tertiary-btn:hover{
    transform:scale(1.1);
    background-color: white;
    color:#022350;
    border:none;
    transition:all .2s linear;
}

.main-product-container{
    width:70vw;
    height:auto;
    margin:3rem 3rem auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    text-align: center;
    gap:3rem;
    position:relative;
    top:0;
    left:0;
    z-index:200;
}


.main-product-container .primary-btn{
    position: relative;
    bottom: 3rem;}

.main-product-image{
    width:70%
}

.hero-images-container{
    width:70vw;
    margin:auto;
    
}

.hero-images-container img{
    width:80%;
    min-width:300px;
    /* position:absolute;
    top:10vh;
    left:0; */
}

.button-container{
    display:flex;
    flex-direction:row;
    justify-content: space-between;

}
.button-container button{
    width:40%;
}

.hero-image-2{
    /* background-color: green; */
   
    /* left:10vw !important; */
    animation: enterScreen ease-in-out 1;
    margin-left:10vw;
    position:absolute;
    top:10vh;
    left:0;
    z-index:0;
}

.hero-image-1{
    animation: enterScreen ease-in-out 1.5s 1;
    filter:opacity(0.8);
    position:absolute;
    top:10vh;
    left:0;
    z-index:1;
}

.main-product-highlight-container{
    width:90vw;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    text-align:left
}

.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

img{
    width: 100%;
    margin: auto;
}

.value p{
    line-height:2rem;
}


.value img{
    width: 100%;
    max-width:200px;
    max-height: 16vh;
    margin: auto;
}

.product-features-container, .values-container{
    width:90vw;
    /* background-color:#d3cccb; */
    padding:1rem;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}
.intro-video{
    animation: closeIntro 2s 10s linear forwards;
}

.values-container{
    background-color: white;
    margin-top:5rem;
}
.product-feature, .value{
    width:90%;
    /* max-height:40vh; */
    /* margin:1rem; */
    padding:0 1rem;
    /* background-color: #022350; */
    color:black;
    text-align:center;
}

.value{
    margin:1rem;
    padding:1rem;
    background-color: #022350; 
    color:white;
}
.value p span:nth-child(1){
    color:#d47e0d;
    font-weight:700;
    font-size: 2.986rem;
    padding:2rem;
}
.product-feature p:nth-child(1){
    color:#d47e0d;
    margin:1rem 0;
}

.main-product-header{
    margin-top:5rem;
}

.voucher-action-buttons{
    display:flex;
}

.voucher-action-buttons *{
    width:30%;
}


@media only screen and (min-width: 600px) {
    .hero{
        width:70vw;
    }
    .voucher-container{
        width:70vw;
    }
    .voucher-container *{
        width:70%;
    }
    .voucher-progress-bar-inner{
        width:5%;}

    .values{
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        align-items:center;
    }
    .product-feature{
        text-align:left;
    }
    .product-feature:first-child{
        /* width:48%; */
        height:100%;
        grid-area:feature1;
    }
    .product-feature:nth-child(2){
       /* display:red; */
        grid-area:feature2;
    }
    .product-feature:nth-child(3){
       
        grid-area:feature3;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .hero {
        width:90vw;
        flex-direction: row;
        justify-content: space-between;
        /* margin:0; */
    }
    .hero .left{
        width:40vw;
        
    }
    .hero .right {
        width:45vw;}

    .main-product-container{
        width:90vw;
        flex-direction: row;
        margin:0;
        margin-bottom:2rem;
    }
    .value{
        width:300px;
        border-radius: 1vmax;

    }

    .main-product-container .left{
        width:40vw;
        text-align:left;
    }
    .product-features-container-2{
        display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    }

    .product-features-container-2 p{
        /* font-size: .833rem; */
        line-height: 1.3rem;
        margin:.5rem;
    }

    .product-features-container-2 .tertiary-header{
        margin:0;
    }

  } 
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .main-product-highlight-container{
        flex-direction: row;
    }
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .values-container{
        position:relative;
        /* top:7vh; */
    }
  }

 

@keyframes enterScreen{
    from {
        filter:opacity(0);
        left:-5vw;
    } to {
        filter:opacity(1);
        left:0;
    }
}

@keyframes enterScreen2{
    from {
        filter:opacity(0);
        left:-5vw !important;
    } to {
        filter:opacity(1);
        left:10vw !important;
    }
}

@keyframes closeIntro {
    0%{
        opacity: 100%;
    } 50%{
        opacity:50%;
    }100%{
        opacity:0%;
        display:none;
    }
}