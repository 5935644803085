
/* LoanRequestPage.css */

.loan-request-container {
  font-family: 'Inter', sans-serif;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  color: #000;
}

.loan-request-container h1 {
  font-size: 2rem;
  color: #1a73e8;
  text-align: center;
  margin-bottom: 1.5rem;
}

.loan-form {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.form-group {
  width:100%;
  text-align:left;
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"], .form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-group textarea{
  min-height:250px;
}

.form-group input:focus {
  border-color: #1a73e8;
  outline: none;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.legal {
  display: flex;
  align-items: center;
}

.legal input[type="checkbox"] {
  margin-right: 0.5rem;
}

.submit-button {
  background: #1a73e8;
  color: #fff;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  background: #1558b8;
}
