
/* HomePage.css */
.voucher-header{
  background-image: url('../resources/charity_bg.jpg');
  background-size: cover;
  padding:1rem;
}
/* .voucher-header p:first-child{
  font-weight: 500;
  font-size: 2.9rem;
    color:#022350;
    cursor: default;
    line-height: 3rem;
    
} */
.voucher-header p{
  background-color: rgba(255, 255, 255, 0.8);
  padding:3rem;
  color:black;
  border-radius:1vmax;
}
/* .voucher-header{
  height:40vh;
  background-image: url('../resources/charity_bg.jpg');
  background-size: cover;
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;
  bottom:8vh;
}
.voucher-header p{
  padding:.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color:#ffffff !important;
} */
.primary-header{
  font-weight: 500;
  font-size: 2.9rem;
    color:#022350;
    cursor: default;
    line-height: 3rem;
}
.homepage-container {
  font-family: 'Inter', sans-serif;
  text-align: center;
  padding: 2rem;
  color: #000;
}
/* .secondary-btn{
  display:block;
  margin-left:80vw;
} */

.header {
  margin: 2.5rem 0;
}

.header h1 {
  font-size: 2.5rem;
  color: #1a73e8;
}

/* .header p {
  font-size: 1.25rem;
  color: #555;
} */


.service-list-item{
  width:350px;
  margin-bottom:5rem;
}

/* .get-started-button {
  display: inline-block;
  padding: 0.75rem 2rem;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 1.25rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
} */

/* .get-started-button:hover {
  background-color: #000000;
} */
@media (max-width: 600px) {
.features{
  flex-direction: column;
}
}